import React from "react"
import icon from "../images/githl-mtn-icon.png"
import Layout from "../components/Layout"
import Row from "react-bootstrap/cjs/Row"
import Col from "react-bootstrap/cjs/Col"
import SEO from "../components/Seo";


interface ContactUsProps {
    location: any;
}

interface ContactUsState {
}

export default class ContactUs extends React.Component<ContactUsProps, ContactUsState> {
    constructor(props: ContactUsProps) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <Layout location={this.props.location}>
                <SEO title="Contact Us"/>
                <div className="container">
                    <div className="container d-flex pl-3 pr-3 align-self-center justify-content-center">
                        <Row className="justify-content-center align-self-center">
                            <Col className="text-center logo-column mt-3 mb-3" sm={4}>
                                <h1 className="logo-header-full">
                                    <a href="/">
                                        <img
                                            className="icon-logo"
                                            src={icon}
                                            alt="gear-in-headlights-icon"
                                        />
                                    </a>
                                </h1>
                                <h1>Contact Us</h1>
                                <h2>
                                    <a href="mailto: gearinheadlights@gmail.com">
                                        <i className="far fa-envelope social-icon"/>
                                    </a>
                                    <a href="https://instagram.com/gearinheadlights">
                                        <i className="fab fa-instagram social-icon"/>
                                    </a>
                                    <a href="https://facebook.com/gearinheadlights">
                                        <i className="fab fa-facebook-f social-icon"/>
                                    </a>
                                </h2>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Layout>
        )
    }
}